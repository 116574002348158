import {
   MDBBtn,
   MDBCard,
   MDBCardBody,
   MDBCardFooter,
   MDBCardHeader,
   MDBCol,
   MDBContainer,
   MDBInput,
   MDBRow,
   MDBIcon,
} from "mdb-react-ui-kit";

import BasketItems from "../components/basketCard.";

import { useEffect, useState } from "react";

export default function Home() {
   const [basket1, setBasket1] = useState([]);
   const [basket2, setBasket2] = useState([]);

   const selectedItems = [];
   const selectedItems2 = [];

   const [basketAElements, setBasketAElements] = useState([]);
   const [basketBElements, setBasketBElements] = useState([]);

   const [basketName1, setBasketName1] = useState([]);
   const [basketQuantity1, setBasketQuantity1] = useState([]);

   const [basketName2, setBasketName2] = useState([]);
   const [basketQuantity2, setBasketQuantity2] = useState([]);

   const [active, setActive] = useState(false);
   const [border, setBorder] = useState({});
   const [active2, setActive2] = useState(false);
   const [border2, setBorder2] = useState(false);

   const selectItem1 = (e, items) => {
      const ifExist = selectedItems.find(
         (itemName) => itemName.name === items.name
      );

      if (ifExist !== undefined) {
         const indexNumber = selectedItems.findIndex(
            (item) => item.name === items.name
         );
         console.log(indexNumber);
         selectedItems.splice(indexNumber, 1);
      } else {
         selectedItems.push(items);
      }

      console.log(selectedItems);
   };

   const selectItem2 = (e, items) => {
      const ifExist = selectedItems2.find(
         (itemName) => itemName.name === items.name
      );

      if (ifExist !== undefined) {
         const indexNumber = selectedItems2.findIndex(
            (item) => item.name === items.name
         );
         console.log(indexNumber);
         selectedItems2.splice(indexNumber, 1);
      } else {
         selectedItems2.push(items);
      }

      console.log(selectedItems2);
   };

   const addItem = (basket, items) => {
      if (items.name.length === 0 && items.quantity.length === 0) {
         alert("Please enter the field items");
      } else {
         const isExisting = basket.find((i) => i.name === items.name);

         if (isExisting) {
            const updatedBasketItem = {
               ...isExisting,
               quantity: isExisting.quantity + items.quantity,
            };

            const updatedBasket = basket.map((item) =>
               item.name === item.name ? updatedBasketItem : item
            );

            basket === basket1
               ? setBasket1(updatedBasket)
               : setBasket2(updatedBasket);
         } else {
            // If the item doesn't exist, add it to the basket
            const newBasket = [...basket, items];
            basket === basket1 ? setBasket1(newBasket) : setBasket2(newBasket);
         }
      }
   };

   const deleteItem = (basket, items) => {
      const updatedBasket = basket.filter((item) => item.name !== items.name);
      basket === basket1
         ? setBasket1(updatedBasket)
         : setBasket2(updatedBasket);
   };

   useEffect(() => {
      const basketItemsA = basket1.map((items) => (
         <MDBRow
            style={{ border }}
            tag="form"
            className="basketItems px-2 my-3  p-0"
         >
            <label
               onClick={(e) => {
                  selectItem1(e, items);
               }}
               htmlFor={items.name}
               className="d-flex"
            >
               <MDBCol className="p-0 d-flex align-items-center" md={9}>
                  <span className="p-0">{items.name}</span>
               </MDBCol>
               <MDBCol className="p-0 d-flex align-items-center" md={3}>
                  <span className="p-0">Qty: {items.quantity}</span>
               </MDBCol>

               <MDBCol className="p-0 d-flex align-items-center" md={1}>
                  <MDBIcon
                     onClick={() => deleteItem(basket1, items)}
                     fas
                     icon="times"
                  />
               </MDBCol>
            </label>
         </MDBRow>
      ));
      setBasketAElements(basketItemsA);
   }, [basket1, border]);

   useEffect(() => {
      const basketItemsB = basket2.map((items) => (
         <MDBRow
            style={
               border2
                  ? {
                       border: "2px solid rgb(144, 252, 216)",
                    }
                  : {}
            }
            tag="form"
            className="basketItems px-2 my-3  p-0"
         >
            <label
               onClick={(e) => {
                  selectItem2(e, items);
               }}
               htmlFor={items.name}
               className="d-flex"
            >
               <MDBCol className="p-0 d-flex align-items-center" md={9}>
                  <span className="p-0">{items.name}</span>
               </MDBCol>
               <MDBCol className="p-0 d-flex align-items-center" md={3}>
                  <span className="p-0">Qty: {items.quantity}</span>
               </MDBCol>

               <MDBCol className="p-0 d-flex align-items-center" md={1}>
                  <MDBIcon
                     onClick={() => deleteItem(basket2, items)}
                     fas
                     icon="times"
                  />
               </MDBCol>
            </label>
         </MDBRow>
      ));
      setBasketBElements(basketItemsB);
   }, [basket2, border2]);

   useEffect(() => {
      if (active) {
         setBorder(true);
      } else {
         setBorder(false);
      }
   });
   useEffect(() => {
      if (active) {
         setBorder(true);
      } else {
         setBorder(false);
      }
   });

   return (
      <>
         <MDBContainer>
            <MDBRow className="min-100vh d-flex justify-content-center align-items-center">
               <MDBCol lg={4}>
                  <MDBCard className="cardBasket">
                     <MDBCardBody>
                        <div className="d-flex">
                           <h4>Basket A</h4>
                           <span className=" mx-2 numberOfBasket d-flex align-items-center justify-content-center">
                              {basket1.length}
                           </span>
                        </div>
                        <div>{basketAElements}</div>

                        <div>
                           <MDBRow
                              tag="form"
                              className="p-1 d-flex paddingColor"
                           >
                              <MDBCol className="py-2 " md={8}>
                                 <MDBInput
                                    className="inputColor"
                                    type="text"
                                    name="name"
                                    label="Name"
                                    onChange={(e) =>
                                       setBasketName1(e.target.value)
                                    }
                                    required
                                 />
                              </MDBCol>
                              <MDBCol className="py-2 -p-0" md={3}>
                                 <MDBInput
                                    name="quantity"
                                    className="inputColor"
                                    type="number"
                                    label="Qty"
                                    onChange={(e) =>
                                       setBasketQuantity1(
                                          Number(e.target.value)
                                       )
                                    }
                                    required
                                 />
                              </MDBCol>
                              <MDBCol
                                 className="p-0 px-1 d-flex align-items-center justify-content-center"
                                 md={1}
                              >
                                 <MDBIcon
                                    type="submit"
                                    onClick={() =>
                                       addItem(basket1, {
                                          name: basketName1,
                                          quantity: basketQuantity1,
                                       })
                                    }
                                    fas
                                    icon="plus"
                                 />
                              </MDBCol>
                           </MDBRow>
                        </div>
                     </MDBCardBody>
                  </MDBCard>
               </MDBCol>
               <MDBCol md={1} className="d-flex flex-column align-items-center">
                  <MDBBtn className="p-0 arrowButton mx-1    my-1">
                     <MDBIcon
                        className="arrowIcon"
                        fas
                        icon="arrow-right"
                        size="2x"
                     />
                  </MDBBtn>
                  <MDBBtn className="p-0 arrowButton mx-1    my-1">
                     <MDBIcon
                        className="arrowIcon"
                        fas
                        icon="arrow-left"
                        size="2x"
                     />
                  </MDBBtn>
               </MDBCol>
               <MDBCol lg={4}>
                  <MDBCard className="cardBasket">
                     <MDBCardBody>
                        <div className="d-flex">
                           <h4>Basket B</h4>
                           <span className=" mx-2 numberOfBasket d-flex align-items-center justify-content-center">
                              {basket2.length}
                           </span>
                        </div>
                        <div>{basketBElements}</div>

                        <div>
                           <MDBRow
                              tag="form"
                              className=" p-1 d-flex paddingColor"
                           >
                              <MDBCol className="py-2 " md={8}>
                                 <MDBInput
                                    className="inputColor"
                                    type="text"
                                    name="name"
                                    label="Name"
                                    onChange={(e) =>
                                       setBasketName2(e.target.value)
                                    }
                                    required
                                 />
                              </MDBCol>
                              <MDBCol className="py-2 -p-0" md={3}>
                                 <MDBInput
                                    name="quantity"
                                    className="inputColor"
                                    type="number"
                                    label="Qty"
                                    onChange={(e) =>
                                       setBasketQuantity2(
                                          Number(e.target.value)
                                       )
                                    }
                                    required
                                 />
                              </MDBCol>
                              <MDBCol
                                 className="p-0  px-1 d-flex align-items-center justify-content-center"
                                 md={1}
                              >
                                 <MDBIcon
                                    type="submit"
                                    onClick={() =>
                                       addItem(basket2, {
                                          name: basketName2,
                                          quantity: basketQuantity2,
                                       })
                                    }
                                    fas
                                    icon="plus"
                                 />
                              </MDBCol>
                           </MDBRow>
                        </div>
                     </MDBCardBody>
                  </MDBCard>
               </MDBCol>
            </MDBRow>
         </MDBContainer>
      </>
   );
}
